import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from './Animate.jsx';
import Loader from './Loader.jsx';
import ListAbsoluteMain from './ListAbsoluteMain.jsx';
import AnimateChange from './AnimateChange.jsx';
import setSpacesInText from '../functions/setSpacesInText';
import removeTransition from '../functions/removeTransition';

class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderRow = this.renderRow.bind(this);

        this.parent = React.createRef();
    }

    info = {
        winners: {
            mainCol: 'date',
            cols: ['email', 'phone', 'prize'],
            supports: {
                date: 'Дата розыгрыша',
                email: 'E-mail',
                phone: 'Номер телефона',
                prize: 'Приз',
            },
        },
        cheques: {
            mainCol: 'date',
            cols: ['fd', 'status', 'result'],
            supports: {
                date: 'Дата загрузки',
                fd: 'ФД',
                status: 'СТАТУС',
                result: 'РЕЗУЛЬТАТ',
            },
        },
    };

    renderRow({ item, prop: key, key: index, isShow }) {
        const { name, device } = this.props;
        const info = this.info[name];

        return (
            <div className="table__rowWrapper">
                <div
                    className={`table__row ${
                        index % 2 === (device === 'mobile' ? 0 : 1) ? '_odd' : ''
                    }`}
                    key={key}
                    style={{
                        zIndex: !isShow ? 1 : index + 2,
                    }}
                >
                    <div className={`table__colWrapper _main`}>
                        <div className="table__col">{item[info.mainCol]}</div>
                    </div>
                    <div className={`table__colWrapper _other`}>
                        {info.cols.map((colName) => (
                            <div className={`table__col _${colName}`} key={colName}>
                                <div className="table__colInner">
                                    {device === 'mobile' && name === 'cheques' ? (
                                        <>
                                            <span className="_support">
                                                {info.supports[colName]}:{' '}
                                            </span>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: setSpacesInText(item[colName]),
                                        }}
                                    ></span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    checkReady() {
        const { isReady } = this.props;

        if (isReady && !this.isReady) {
            this.isReady = true;

            removeTransition({ item: '.table__rowWrapper', isCurrent: true });
        }
    }

    componentDidUpdate() {
        this.checkReady();
    }

    componentDidMount() {
        this.checkReady();
    }

    render() {
        const {
            name,
            items,
            device,
            showLoader = false,
            isReady = false,
            emptyTitle,
            renderUpdateKey,
        } = this.props;
        const info = this.info[name];
        const minHeight = items.length === 0 ? 24 : undefined;

        return (
            <>
                <div ref={this.parent} className={`table _${name}`}>
                    <div className="table__row _head">
                        <div className={`table__colWrapper _main`}>
                            <div className="table__col">{info.supports[info.mainCol]}</div>
                        </div>
                        <div className={`table__colWrapper _other`}>
                            {info.cols.map((colName, key) => (
                                <div className={`table__col _${colName}`} key={colName}>
                                    {info.supports[colName]}
                                    {device === 'mobile' &&
                                    name === 'cheques' &&
                                    key !== info.cols.length - 1 ? (
                                        <>,&nbsp;</>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="table__content">
                        <Animate className="table__loader" isShow={showLoader || !isReady}>
                            <div className="table__loaderItem">
                                <Loader />
                            </div>
                        </Animate>

                        <ListAbsoluteMain
                            className="table__contentInner"
                            items={items}
                            classNames={[`_${name}`]}
                            renderChildren={(inner) => (
                                <>
                                    {inner}
                                    <Animate
                                        className="table__empty"
                                        isShow={items.length === 0 && isReady}
                                    >
                                        <AnimateChange
                                            className="table__emptyInner"
                                            renderKey={emptyTitle}
                                            parentStyles={['height']}
                                        >
                                            <div
                                                className="table__emptyTitle"
                                                dangerouslySetInnerHTML={{
                                                    __html: setSpacesInText(emptyTitle),
                                                }}
                                            ></div>
                                        </AnimateChange>
                                    </Animate>
                                </>
                            )}
                            renderItem={this.renderRow}
                            classNameItem="table__rowWrapper"
                            prop="id"
                            paramsParent={{ width: true }}
                            styles={['height']}
                            minHeight={minHeight}
                            resizeParent={document.querySelector('.body')}
                            keyRender={renderUpdateKey}
                        />
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(Table);

Table.propTypes = {
    name: PropTypes.string,
    items: PropTypes.array,
    device: PropTypes.string,
    showLoader: PropTypes.bool,
    isReady: PropTypes.bool,
    emptyTitle: PropTypes.string,
    renderUpdateKey: PropTypes.any,
};
